import NotificationService from 'services/NotificationService';
import { useDispatch, useSelector } from 'store';
import {
  getAllNotification,
  setOneNotificationSeenStatus
} from 'store/reducers/notificationSlice';
import {
  TFilterNotification,
  TNotification,
  TNotificationSeenStatus
} from 'types/notification';

const useNotificationHook = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(state => state.notification);

  const fetchAllNotification = async (
    filters: TFilterNotification
  ): Promise<TNotification[]> => {
    try {
      const resp = await NotificationService.fetchAll(filters);
      const results: TNotification[] = resp?.data?.data || [];
      dispatch(getAllNotification({ results }));
      return results;
    } catch (e) {
      dispatch(getAllNotification({ results: [], meta: {} }));
      console.log('getAllNotification', e);
      throw e;
    }
  };

  const updateNotificationSeenStatus = async (
    id: number,
    data: TNotificationSeenStatus
  ) => {
    try {
      await NotificationService.updateSeenStatus(id, data);
      dispatch(setOneNotificationSeenStatus({ id, row: data }));
    } catch (e) {
      console.log('setOneNotificationSeenStatus', e);
      throw e;
    }
  };

  return {
    fetchAllNotification,
    updateNotificationSeenStatus,

    notifications
  };
};

export default useNotificationHook;
