import { createSlice } from '@reduxjs/toolkit';
import { TNotification } from 'types/notification';

interface NotificationState {
  notifications: TNotification[];
}

const initialState: NotificationState = {
  notifications: []
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getAllNotification(state, action) {
      state.notifications = action.payload.results || [];
    },
    setOneNotificationSeenStatus(state, action) {
      state.notifications = state.notifications.map(item => {
        if (item.id !== action.payload.id) return item;
        else
          return {
            ...item,
            is_seen: action.payload.row.is_seen
          };
      });
    }
  }
});

export const { getAllNotification, setOneNotificationSeenStatus } =
  notificationSlice.actions;
export default notificationSlice.reducer;
