import api from '../utils/api';
import { serializedObject } from 'helpers/utils';
import {
  TFilterNotification,
  TNotificationSeenStatus
} from 'types/notification';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/notification`;

const fetchAll = (filters: TFilterNotification) => {
  return api.get(`${authEndpoint}/get?${serializedObject(filters)}`);
};

const updateSeenStatus = (id: number, data: TNotificationSeenStatus) => {
  return api.post(`${authEndpoint}/update-seen-status/${id}`, data);
};

export default {
  fetchAll,
  updateSeenStatus
};
